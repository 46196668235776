<template>
  <div class="banner-container">
    <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-radio-group
            @change="handleStatusChange"
            v-model="status"
            style="margin-bottom: 10px;"
          >
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button label="on">上架中</el-radio-button>
            <el-radio-button label="off">已下架</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="12" :offset="0">
          <div style="text-align: right" class="create-option">
            <el-button type="primary" @click="showCreateDialog = true">添加轮播图</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="bannerList.items" border stripe>
      <el-table-column label="banner">
        <template slot-scope="scope">
          <img :src="scope.row.zh.src" width="80" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="bannerList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      :title="isEdit ? '编辑轮播图' : '添加轮播图'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="onClose"
    >
      <el-form ref="form" :model="postForm" :rules="rules" label-width="80px">
        <el-form-item label="轮播图(中)">
          <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :limit="1"
            :file-list="zhBannerPicList"
            :on-success="zhUploadSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接(中)">
          <el-input v-model="postForm.zh.link"></el-input>
        </el-form-item>
        <el-form-item label="链接(pro中)">
          <el-input v-model="postForm.zh.deep_link"></el-input>
        </el-form-item>
        <el-form-item label="轮播图(英)">
          <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :limit="1"
            :file-list="enBannerPicList"
            :on-success="enUploadSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接(英)">
          <el-input v-model="postForm.en.link"></el-input>
        </el-form-item>
        <el-form-item label="链接(pro英)">
          <el-input v-model="postForm.en.deep_link"></el-input>
        </el-form-item>
        <el-form-item label="排序编号" prop="weight">
          <el-input
            v-model="postForm.weight"
            type="number"
            min="0"
            max="99"
            placeholder="请输入排序编号，最高为0"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否上架">
          <el-switch v-model="postForm.active"> </el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postBanner, editBanner } from "@/api/banner";
import dayjs from "dayjs";

export default {
  data() {
    const validateSort = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入排序编号"));
      } else if (+value < 0 || +value > 99) {
        callback(new Error("排序编号为0-99的整数"));
      }
      callback();
    };
    return {
      columns: [
        { id: "zh.link", label: "中文链接" },
        { id: "weight", label: "排序编号" },
        {
          id: "active",
          label: "状态",
          formatter: (row) => {
            switch (row.active) {
              case false:
                return <el-tag>已下架</el-tag>;
              case true:
                return <el-tag type="success">上架中</el-tag>;
              default:
                return row.active;
            }
          },
        },
        {
          id: "update_time",
          label: "最后操作时间",
          formatter: (row) => {
            return dayjs(row.update_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      postForm: {
        active: false,
        zh: {
          src: "",
          link: "",
          deep_link: "",
        },
        en: {
          src: "",
          link: "",
          deep_link: "",
        },
        weight: "",
      },
      active: 0,
      isEdit: false,
      showCreateDialog: false,
      currentPage: 1,
      pageSize: 30,
      status: "all",
      rules: {
        weight: [
          { required: true, message: "请输入排序编号", trigger: "blur" },
          {
            validator: validateSort,
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["bannerList"]),
    zhBannerPicList() {
      if (this.postForm.zh.src) {
        return [
          {
            name: "zh_url",
            url: this.postForm.zh.src,
          },
        ];
      }
      return [];
    },
    enBannerPicList() {
      if (this.postForm.en.src) {
        return [
          {
            name: "en_url",
            url: this.postForm.en.src,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    fetchData() {
      let query = {};
      query.page = this.currentPage;
      query.page_size = this.pageSize;
      query.status = this.status;
      this.$store.dispatch("banner/getBannerList", query);
    },
    handleStatusChange() {
      this.pageSize = 30;
      this.currentPage = 1;
      this.fetchData();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handleEdit(data) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        id: data.id,
        active: data.active,
        zh: {
          src: data.zh.src,
          link: data.zh.link,
          deep_link: data.zh.deep_link,
        },
        en: {
          src: data.en.src,
          link: data.en.link,
          deep_link: data.en.deep_link,
        },
        weight: data.weight,
      };
    },
    zhUploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.postForm.zh.src = data.data;
      }
    },
    enUploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.postForm.en.src = data.data;
      }
    },
    onClose() {
      this.postForm = {
        active: false,
        zh: {
          src: "",
          link: "",
          deep_link: "",
        },
        en: {
          src: "",
          link: "",
          deep_link: "",
        },
        weight: "",
      };
      this.showCreateDialog = false;
      this.isEdit = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    submit() {
      const bannerData = this.getBannerData();
      // console.log("submit", bannerData);
      if (this.isEdit) {
        editBanner(bannerData).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      } else {
        postBanner(bannerData).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      }
    },
    getBannerData() {
      let json = {
        active: this.postForm.active,
        zh: {
          src: this.postForm.zh.src,
          link: this.postForm.zh.link,
          deep_link: this.postForm.zh.deep_link,
        },
        en: {
          src: this.postForm.en.src,
          link: this.postForm.en.link,
          deep_link: this.postForm.en.deep_link,
        },
        weight: +this.postForm.weight,
      };
      if (this.postForm.id) {
        json["id"] = this.postForm.id;
      }
      return json;
    },

    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {
        active: false,
        zh: {
          src: "",
          link: "",
          deep_link: "",
        },
        en: {
          src: "",
          link: "",
          deep_link: "",
        },
        weight: "",
      };
      this.currentPage = 1;
      this.isEdit = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.banner-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
